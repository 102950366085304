import { useState, useEffect } from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import Select from 'react-select'
import { useLocalStorage } from 'usehooks-ts'

import { DirectoryMemberMembershipGroup } from 'src/components/InformerDirectoryUserCell'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'web/src/Providers'

interface SearchGroupsProps {
  searchVal?: string
  setSearchVal?: (search: string) => void
  localStorageName?: string
  setSelectedGroups?: (groupList: number[]) => void
  specifiedMembershipGroups?: DirectoryMemberMembershipGroup[]
  placeholder?: string
  GAEventCategory?: string
  includeSearch?: boolean
  isMobile?: boolean
}

const SearchGroups = ({
  searchVal,
  setSearchVal,
  localStorageName,
  setSelectedGroups,
  specifiedMembershipGroups,
  placeholder = 'Search',
  GAEventCategory = 'Uncategorized',
  includeSearch = true,
  isMobile = false,
}: SearchGroupsProps) => {
  const { currentUser } = useAuth()
  const membershipGroups =
    specifiedMembershipGroups || currentUser.membershipData.membershipGroups
  const groupOptions = membershipGroups.map((group) => {
    return {
      label: group.name,
      value: group.id,
    }
  })

  const [groupsLocalStorageData, setLocalStorageGroupData] = useLocalStorage(
    localStorageName,
    [],
  )

  const [searchWidth, setSearchWidth] = useState('w-2')
  const { trackEvent } = useAnalytics()

  // track search value when user clicks away instead of on every key stroke
  const sendEvent = (event) => {
    trackEvent(GAEventCategory, 'SearchGroups - Search', {
      searchValue: event.target.value,
    })
  }

  useEffect(() => {
    if (setSearchVal) setSearchWidth(searchVal ? 'w-64' : 'w-64')
  }, [searchVal])

  const updateGroups = (groups) => {
    const groupList = []
    for (const group of groups) {
      groupList.push(group.value)
    }
    setSelectedGroups(groupList)
    setLocalStorageGroupData(groups)
  }

  return (
    <div
      className={`flex ${isMobile ? 'flex-col w-full' : ''}`}
      data-testid="search-groups-wrapper"
    >
      {includeSearch && setSearchVal && (
        <div
          className={`flex flex-col justify-center ${isMobile ? 'pb-2' : ''}`}
        >
          <div className="flex rounded-md h-10 shadow-sm border-gray-300 border">
            <div className="relative flex-grow focus-within:z-10 ">
              <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none ">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="mobile-search-candidate"
                id="mobile-search-candidate"
                className="focus:ring-indigo-500 focus:border-indigo-500 block rounded-none rounded-l-md pl-10 sm:hidden border-gray-300 h-full w-full"
                placeholder={placeholder}
                onChange={(e) => setSearchVal(e.target.value)}
                onBlur={sendEvent}
              />
              <input
                type="search"
                name="desktop-search-candidate"
                id="desktop-search-candidate"
                data-testid="home-links-text-search"
                className={`hidden focus:ring-indigo-500 focus:border-indigo-500 rounded-lg pl-9 sm:block sm:text-sm border-gray-300 h-full transition-all duration-300 ease-in-out focus:w-64 hover:w-64 hover:pr-2 focus:pr-2 ${searchWidth}`}
                placeholder={placeholder}
                onChange={(e) => setSearchVal(e.target.value)}
                onBlur={sendEvent}
              />
            </div>
          </div>
        </div>
      )}
      {groupOptions.length > 0 && (
        <div
          className={`flex flex-col justify-center ${isMobile ? 'pb-2' : ''}`}
          data-testid="search-groups-wrapper"
        >
          <Select
            id="msForm"
            options={groupOptions}
            placeholder="Select Groups"
            isMulti
            onChange={(e) => {
              updateGroups(e)
              let groupSelection = ''
              if (e.length === 0) {
                groupSelection = 'no group'
              } else if (e.length === 1) {
                groupSelection = e[0].label
              } else {
                const selectedGroups = e.map((element) => element.label)
                groupSelection = selectedGroups.join(', ')
              }

              trackEvent(GAEventCategory, 'SearchGroups - search groups', {
                eventCategory: GAEventCategory,
                groupSelection: groupSelection,
              })
            }}
            value={groupsLocalStorageData || []}
            className={`align-middle focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
              !isMobile && 'ml-2'
            }`}
          />
        </div>
      )}
    </div>
  )
}

export default SearchGroups
